@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap'); 


*{
  font-family: 'Montserrat', sans-serif;
}
.navbar{
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-brand{
  padding: 0;
}
.header{
  background-color: white;
}
.nav-item{
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}
.nav-item:hover .sub-ul{
  display: block;
  cursor: pointer;
}
.nav-item .fa-chevron-down{
  font-size: 14px;
}
.sub-ul li:hover{
  background:   #f5f5f5;
  color: #364465  ;
  border-radius:5px;

}
.header .sub-ul{
  background: white;
  position: absolute;
  display: none  ;
  padding: 0;
  width: 150px;
  color: black;
  border-radius:5px;
  cursor: pointer;
  top: 57px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  
  z-index: 10;
}
.sub-ul li{
  list-style: none;
  font-weight: 600;
  padding: 5px 15px;
  font-size: 14px;

}
.sub-ul a{
  color: inherit;
  text-decoration: none;
}
.search-area{
  background-color: white;
  padding: 5px 20px;
  box-shadow: 0 10px 12px rgba(77,60,160,0.1);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  display: flex;
  justify-content: space-between;

}

.banner{
  min-height: 500px;
  color:White;
  background: #eff6ff no-repeat;
  background-size: cover!important;
    background-position: center!important;
    position:relative;
}
.inp-search{
  border:0;
  outline: 0 none;
  width: 100%;
  padding: 15px;

}
.find-search{
  position: absolute;
    background: white;
    top:224px;
    width: 100%;
    border-radius: 8px;
    color: black;
    padding: 20px;
    text-align: start;
    overflow-y: scroll;
}
.find-search a{
  display: block;
}
.bg-custom{
  position: absolute;
  background: rgb(29 34 43 / 69%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.btn-search{
  background:#253156;
  border-radius: .2rem;
  border: 0;
  color: white;
  padding: 12px 25px;
  min-width: 130px;
}
.banner-area{
  position: relative;
}
.banner-title{
  color:white;
  font-size: 50px;
  /*color: #1a2340;*/
}
.title-area{
  margin-bottom: 40px;
}
.title{
  font-size: 40px;
  margin: 0;
  color: #3a4867de;
  font-weight: bold;
}
.title-description{
  font-size: 15px;
  margin: 0;
}

.item-image{
  overflow: hidden;  
}
.item-image img{
  min-height: 240px;
  width: 100%;
  max-height: 240px;
  object-fit:cover;
  overflow: hidden;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.item{
  margin-bottom: 30px;
  margin-right: 10px;
  margin-left: 10px;
}
.nav-link{
  font-size: 14px;
  font-weight: 600;
  color:#27282e  !important;
}

.navbar-brand{
  font-size: 30px;
  font-weight: bold;
}
.item-description{
  padding:15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

}
.item-description div:nth-child(1){
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-all-re .item-title{
  font-weight: 600;
  text-align: left;
  color: #364465;
}
.section-all-re .item-price{
  font-weight: bold;
  min-width: 100px;
  font-size: 25px;
  color: #364465;
}
.item .fas{
  color:#364465
}
.item-icon{
  color:black;
  font-weight: 500;
  font-size: 15px;
  display: flex;align-items: center;
}
.btn-detail{
  background-color: #364465;
  color:white;
}

.footer{
  padding:4rem 0;
  background: #27282e;
  color:white;
}
.footer .fa-instagram,.footer .fa-facebook,.footer .fa-twitter{
  color: #ffffff8a !important;
  font-size: 22px;
}
.footer-title{
  font-size: 21px;
  font-weight: bold;
}
.footer-ul{
  margin:0;
  padding:0;
}
.footer-ul li{
  list-style: none;
  margin-left: 0;
  margin-bottom: 10px;
  font-size: 15px;
  color: #ffffff8a;
}
.footer-other-text{
  font-size: 15px;
  color: #ffffff8a;
}
.fa-home{
  font-size: 40px
}
.footer .fa-facebook,.footer .fa-twitter,.footer .fa-instagram-square{
  font-size: 20px;
}

.inp-footer{
  outline: 0;
  border: 0;
}
.footer-social{
  display: flex;
}
.footer-social-item{
  width: 45px;
  height: 45px;
  background: #393a42;
  margin: 5px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 50%;
}
.footer-info-item{
  width: 25px;
}
.subscribe-area{
  background-color: white;
  padding: 5px 10px;
  box-shadow: 0 10px 12px rgba(77,60,160,0.1);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.btn-subscribe{
  background: #009688;
  border-radius: .2rem;
  border: 0;
  color: white;
  padding: 12px 25px;
}

section {
  /*padding-top: 80px;
  padding-bottom: 110px;*/
}
.best-estate-item{
  box-shadow: 0 0 10px 0 rgb(62 28 131 / 10%);
  margin: 10px;
  border-radius: 10px;
  background: #f7f9fc;
  overflow: hidden;
}
.best-estate-item h4{
  margin: 0;
  font-size: 16px;
  color: #364465;
  font-weight: bold;
}
.best-estate-content a{
  color: inherit;
  text-decoration: none;
}
.best-estate-item .best-estate-img{
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  transition: 0.5s linear;
  cursor: pointer;
}
.best-estate-item .best-estate-img:hover{
  transform: scale(1.2);
}
.best-estate-item span{
  font-size: 14px;
  color: #364465;
}
.best-estate-content{
  padding: 15px;
  border-bottom: 1px solid #ececec;
  background: white;
}
.best-estate-features{
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.best-estate-feature{
  margin-right: 20px;
}
.best-estate-feature span{
  margin-left: 5px;
}
.best-estate-features .best-estate-price{
  font-size: 22px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.section-best-estate{
  background: #82858c1a;
}
.best-estate-state{
  color: white;
  position: absolute;
  top: 9px;
  left: -38px;
  transform: rotate(-45deg);
  background: crimson;
  padding: 5px 35px;
  font-size: 13px;
}
.best-estate-img-area{
  position: relative;
  overflow: hidden;
}
.bg-crimson{
  background: crimson;
}
.bg-green{
  background: #10a03c;
}

.sbs-area-inp{
  padding: 10px;
    border: 1px solid gainsboro;
    outline: none;
    border-radius: 5px;
}

.section-subscribe{
      background: #4b5698;
}
.section-subscribe .title{
  color: white;
}
.sbs-description{
  color: #e6e6e6;
}



.team{
  border:1px solid #eeeeee;
  
}
.team .team-name{
  text-align: center;
  color: #3a4867de;
  font-size: 24px;
  font-weight: bold;
}
.team .team-position{
  text-align: center;
  color: #53607b; 
  margin-top: 10px;
  margin-bottom: 20px;
}
.team .team-img{
  text-align: center;
}
.team .team-img img{
  width: 100%;
  height: 500px;
  object-fit: cover;
}
.team .team-info{
  padding:30px 5px;
  transition: 0.3s;
}
.team .social-links {
  display: flex;
  justify-content: center;
}
.team .social-item{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #ffffffab;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  background: #525659;
  color: white;
  transition: 0.3s;
}

.team:hover .team-info{
  background: #27282e;
  cursor: pointer;
}
.team:hover .team-name{
  color: white;
}
.team:hover .team-position{
  color: #ffffffab;
}
.team:hover .social-item{
  background: white;
  color: #525659;
}

.section-references{
  background: #f2f3f3;
}
.section-references img{
  object-fit: cover;
  height: 100px !important;
}
.section-references .slick-slide div{
  margin: 10px; 
}


.page-top{
  background: #364465;
  padding: 50px;
  color: white;
}
.page-content{
  padding-top: 5em;
  padding-bottom: 5em;
}
.page-top .page-title{
  font-size: 35px;
  font-weight: bold;
  text-align: center;
}
.page-top .page-description{
  font-size: 22px;
  text-align: center;
}

/* Contact Start */
.contact label{
  display: block;
  color: #27282e;
  font-size: 14px;
  font-weight: bold;
}
.contact .inp-contact,.ta-contact{
  border: 1px solid #e4e4e4;
  background: whitesmoke;
  width: 100%;
  outline: none;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.ta-contact{
  resize: none;
}
.contact .btn-contact{
  border:0;
  padding: 12px 50px;
  background: #8bc34a;
  color: white;
}
.contact .contact-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  background: whitesmoke;
}
.contact .fas{
  font-size: 25px;
}
/* Contact End*/

.section-references,.section-teams,.section-best-estate,.section-all-re{
  padding-top: 70px;
  padding-bottom: 70px;
}

/* Blog Start */
.blog{
  background: #82858c1a;
}
.blog-item{
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 20px;
}
.blog-content{
  padding: 15px;
  background: #fff;
}
.blog-title{
  font-size: 16px;
  font-weight: bold;
  color: #364465;
  margin: 0;
}
.blog-title a{
  color: inherit;
  text-decoration: none;
}
.blog-text{
  color: #797979e0;
  font-size: 13px;
}
.blog-info{
  display: flex;
  margin: 9px 0px;
}
.blog-info-item{
  font-size: 13px;
  color: #757575;
}
.blog-info-item:nth-child(1){
  margin-right: 15px;
}
.blog .fa-calendar-alt,.blog .fa-comments{
  margin-right: 5px;
}
/* Blog End */

/* About Start */
.about{
  background: whitesmoke;
}
.about img{
  border-radius: 10px;
}
.about-text{
  color: #797979e0;
  font-size: 17px;
  margin: 15px 0;
}
.about .fa-long-arrow-alt-right{
  color: crimson;
  font-size: 18px;
  margin-right: 5px;
}
.about-features{
  margin:10px 0px;
}
.about .about-feature{
  font-size: 14px;  
  margin-bottom: 5px;
  font-weight: bold;
  color: #364465;
  display: flex;
  align-items: center;
}
.about-item{
  padding: 30px;
}
/* About End */





/* Media Start */
@media (max-width:500px) {
  .search-area{
    justify-content: center;
    flex-wrap: wrap;
  }
  .find-search{
    top:350px !important;
  }
}
@media (max-width:990px) {
  .sbs-area-inp{
    display: block;
    margin-bottom: 10px;
  }
}
/* Media End */


.blog-detail{
  background: white;
  padding: 10px;
  border-radius: 10px;
}
.blog-detail-category{
  font-size: 12px;
  color: #253156;
  font-weight: bold;
  margin-top: 15px;
  display: block;
}
.blog-detail-title{
  font-weight: bold;
}
.blog-detail-date{
  font-size: 12px;
  color: #797979e0;
}
.blog-detail-content{
  margin-top: 15px;
}
.blog-detail-alttitle{
  font-weight: bold;
  font-size: 20px;
}



.widget{
  background: white;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 10px;
  padding: 25px;
}
.widget:first-child{
  margin-top: 0px;
}
.widget-title{
  font-weight: bold;
  color:#364465;
}
.widget-search-inp{
  width: 100%;
  background: #eeeeee;
  border: 1px solid #25315647;
  outline: none;
  padding: 8px 16px;
  border-radius: 5px;
}
.widget-search-result{
  background: #eeeeee;
  padding: 15px;
  border: 1px solid #b6bac4;
  border-radius: 5px;
  margin-top: 9px;
}
.widget-search-result p{
  margin: 0;
}
.right-sidebar .category-ul {
  margin: 0;
  padding: 0;
}
.right-sidebar .category-ul li{
  list-style: none;
  padding: 0;
  margin: 0;
}

.flat-detail-content{
  background: white;
}
.flat-detail-title{
  font-weight: bold;
}
.fd-address{
  margin: 0;
}
.fd-address i{
  margin-right: 5px;
}
.fd-top{
  display: flex;
  padding: 35px;
  justify-content: space-between;
}
.fd-price{
  font-weight: bold;
  font-size: 40px;
}

.fd-item,.fd-sidebar-item{
  background: white;
  padding: 35px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.fd-item h4,.fd-sidebar-item h4{
  font-weight: bold;
  margin-bottom: 15px;
}

.fd-features .fa-check{
  color: #8bc34b;
}

.fd-features span{
  margin-left: 5px;
  font-weight: 500;
}
.fd-property-detail .col-lg-4 span{
 font-weight: 500;
}
.recently-item:nth-child(2),.recently-item:nth-child(3) {
  margin-bottom: 20px;
}
.recently-item img{
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 10px;
}
.recently-item span{
  margin-left: 10px;
  font-weight: 500;
}

.fd-sidebar-item ul{
  padding-left: 20px;
}

.image-gallery-image{

  object-fit: cover;
}