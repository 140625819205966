

  .project-list {
    margin: 0 auto;
    max-width: 800px;
    padding: 20px;
  }
  
  .animated-heading {
    text-align: center;
    animation: fadeIn 1s ease-in-out;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .project-card {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .project-card h3,
  .project-card h4 {
    margin-bottom: 10px;
  }
  
  