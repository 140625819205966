* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  background-color: #f5f5f5;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 0;
  background-color: #f0f8ff; /* Light blue background */
}

.hero-content {
  max-width: 50%;
  color: #333; /* Dark text for better contrast */
}

.hero-content h1 {
  font-size: 2.5rem;
  margin-bottom: 15px;
}

.hero-content p {
  font-size: 1.1rem;
  line-height: 1.5;
}

.hero-image {
  max-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-image img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Responsive image */
}

.about {
  padding: 50px 10px;
  border-bottom: 1px solid #ddd;
}

.about h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.about p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 15px;
}

.testimonials {
  padding: 50px 0;
  border-bottom: 1px solid #ddd;
}

.testimonials h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.testimonial-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.testimonial {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fafafa; /* Light gray background */
}

.testimonial p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 5px;
}

.testimonial p:first-child {
  font-style: italic; /* Italic for quotes */
}

.exclusive {
  padding: 50px 0;
  border-bottom: 1px solid #ddd;
}

.exclusive h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.exclusive p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 20px;
}

.stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.stats span {
  font-size: 2rem;
  color: #f08000; /* Orange for stats */
}

.exclusive p:last-child {
  font-style: italic;
}

.contact-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  border-radius: 5px;
  background-color: #f0f0f0; /* Light gray background */
}

.contact-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s ease-in-out;
}

.contact-link:hover {
  transform: scale(1.1); /* Slight hover zoom */
  color: #333; /* Darken text on hover */
}

.contact-link span {
  margin-left: 0.5rem;
  font-weight: bold;
}

.contact-link .fa-icon {
  font-size: 1.2rem; /* Adjust icon size as needed */
}

/* General Styles */

h1,
h2,
h3 {
  font-weight: bold;
}

a {
  color: #f08000; /* Orange for links */
}

/* Responsiveness */

@media only screen and (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .hero,
  .about,
  .testimonials,
  .exclusive {
    padding: 30px 0;
  }

  .hero-content,
  .hero-image {
    max-width: 100%;
  }

  .testimonial-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
